<template>
    <div class="resume-bg">
        <div v-if="resumeList.length>0">
            <div v-for="(item,index) in resumeList" :key="index">
                <div class="resume-item"  v-if="item.resume">
                    <div class="title">{{item.name}}</div>
                    <div class="look-btn" @click="previewFile(item)">查看简历</div>
                </div>
            </div>
        </div>
        <div v-else class="notice-empty">
            <img src="@/assets/images/mobile/common/empty1.png" alt="">
            <span>暂无简历</span>
        </div>
    </div>
</template>
<script>
import {
    jobListMb
} from "@/api/mobile/activity";
export default {
    data(){
        return{
            resumeList:[],
            postTypes:['SO_FIX_TUTOR','SO_PART_TIME']
        }
    },
    mounted(){
        this.getResumeList()
    },
    methods:{
        //获取建简历列表
        getResumeList(){
            let params = {
                pageIndex:1,
                pageSize:99999,
                postTypes:this.postTypes.join(',')
            }
            jobListMb(params).then((res) => {
                if(res.code == 0){
                    var resumeArr = []
                    res.data.forEach(el=>{
                        if(el.resume){
                            resumeArr.push(el)
                        }
                    })
                    if(resumeArr.length>0){
                        this.resumeList = resumeArr
                    }
                }
            })
        },
        //点击查看简历
        previewFile(item) {
            if (this.$dd.env.platform !== "notInDingTalk") {
                this.$dd.ready(() => {
                    this.$dd.biz.util.openLink({
                        url: item.resume, //要打开链接的地址
                        onSuccess: function(result) {
                            /**/
                        },
                        onFail: function(err) {}
                    })
                })
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.resume-bg {
    padding:0.28rem;
    height: 100vw;
    background: #F7F8F9;
    .resume-item{
        background:#ffffff;
        padding:0.28rem;
        box-shadow: 0px 0.04rem 0.16rem 0.02rem rgba(220,223,230,0.2);
        border-radius: 0.12rem 0.12rem 0.12rem 0.12rem;
        margin-bottom:0.2rem;
        .title{
            color:#303133;
            font-size:0.28rem;
            margin-bottom:0.28rem;
        }
        .look-btn{
            width: 6.38rem;
            line-height: 0.8rem;
            background: #4392FA;
            border-radius: 0.08rem;
            text-align: center;
            font-size:0.28rem;
            color:#FFFFFF;
            font-weight: bold;
        }
    }
}
.notice-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
    img {
        width: 2.8rem;
        height: 2.8rem;
        margin-bottom: 0.4rem;
    }
    span {
        font-size: 0.28rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #909399;
        line-height: 0.4rem;
    }
}
</style>